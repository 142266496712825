




















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsTable, { TableHeader } from '@/components/VsTable/Index.vue'
import { getSuppressions, deleteSuppression, getSuppressionReport } from '@/api/consoleApi/suppressions'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import VsAddSuppressionModal from '@/modules/lists/components/VsAddSuppressionModal/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import axios from 'axios'

@Component({
    name: 'SuppressionList',
    components: {
        VsTable,
        VsConfirm,
        VsSectionHeader,
        VsDropdownButton,
        VsAddSuppressionModal,
        VsFilterContainer,
    },
})
export default class extends Vue {
    @Prop({ default: 1, required: false }) private refresh?: number
    private loading = false
    private suppressions: any[] = []
    private total = 0
    private tableColumns: TableHeader[] = [
        {
            id: 'id',
            key: 'id',
            label: 'ID',
            visible: true,
        },
        {
            id: 'email_address',
            key: 'email_address',
            label: 'Indirizzo email',
            visible: true,
        },
        {
            id: 'mobile',
            key: 'mobile',
            label: 'Telefono cellulare principale',
            visible: false,
        },
        {
            id: 'source',
            key: 'source',
            label: 'Sorgente',
            visible: true,
            formatter: (value) => this.$t(`lists.suppression.sources['${value}']`).toString(),
        },
    ]

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: 'desc', // desc || asc
    }

    private filters = {
        searchFields: 'email_address', // email_address || mobile
        source: '', // email_address || mobile
        search: '',
    }

    $refs!: {
        confirm: VsConfirm
    }

    get sourceOptions () {
        return [
            {
                label: this.$t('lists.suppression.sources.User'),
                value: 'User',
            },
            {
                label: this.$t('lists.suppression.sources.Administrator'),
                value: 'Administrator',
            },
            {
                label: this.$t(`lists.suppression.sources['SPAM complaint']`),
                value: 'SPAM complaint',
            },
            {
                label: this.$t('lists.suppression.sources["Hard Bounced"]'),
                value: 'Hard Bounced',
            },
        ]
    }

    get params () {
        return {
            search: `${this.filters.searchFields}:${this.filters.search}${this.filters.source ? ';source:' + this.filters.source : ''}`,
            searchFields: `${this.filters.searchFields}:${this.filters.search ? 'like' : '!='}${this.filters.source ? ';source:=' : ''}`,
            orderBy: 'id',
            sortedBy: this.pagination.orderBy,
            searchJoin: 'and',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }
    }

    get countFilters () {
        let count = 0
        if (this.filters.source && this.filters.source.length > 0) count++
        if (this.filters.search && this.filters.search.length > 0) count++
        if (this.filters.searchFields && this.filters.searchFields !== 'email_address') count++
        return count
    }

    beforeMount () {
        this.getSuppressions()
    }

    @Watch('filters', { deep: true, immediate: false })
    private async clearPageAndReset () {
        if (this.filters.searchFields === 'email_address') {
            this.tableColumns[1].visible = true
            this.tableColumns[2].visible = false
        } else {
            this.tableColumns[1].visible = false
            this.tableColumns[2].visible = true
        }
        if (this.pagination.page === 1) {
            await this.getSuppressions()
        } else {
            this.pagination.page = 1
        }
    }

    @Watch('refresh', { immediate: false })
    @Watch('pagination', { deep: true, immediate: true })
    private async getSuppressions () {
        this.loading = true
        try {
            const resp = await getSuppressions(this.params)
            this.total = resp.data.meta.pagination.total
            this.suppressions = resp.data.data
        } catch (e) {
            console.log(e)
            this.total = 0
            this.suppressions = []
        }
        this.loading = false
    }

    private async deleteSuppression (id: string) {
        try {
            await this.$refs.confirm.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteSuppression(id)
            await this.clearPageAndReset()
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.list.deleteSuccess'),
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.list.deleteError'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async downloadReport () {
        try {
            this.loading = true
            const response = await getSuppressionReport()
            const filename = 'global_suppression_list.csv'
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = downloadUrl
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    this.$root.$vsToast({
                        heading: 'Impossibile scaricare il file, report non disponibile',
                        timeout: 3000,
                        aspect: VsToastAspectEnum.alert,
                    })
                }
            }
            console.log(e)
        }
        this.loading = false
    }
}
