var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('VsSectionHeader',{staticClass:"vs-mb-8",attrs:{"size":"medium","heading":"Elenco contatti soppressi"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('VsButton',{staticClass:"vs-flex-auto",attrs:{"size":"medium"},on:{"click":function($event){return _vm.$refs.addSuppressionModal.openModal()}}},[_vm._v(" "+_vm._s(_vm.$t('lists.suppression.addSuppression'))+" ")]),_c('VsDropdownButton',{attrs:{"placement":"bottom-end","size":"medium"}},[_c('VsActionItem',{attrs:{"label":_vm.$t('lists.suppression.importSuppressions'),"to":{
                        name: 'listImportIndexSuppression'
                    }}}),_c('VsActionItem',{attrs:{"label":_vm.$t('lists.suppression.download')},on:{"click":_vm.downloadReport}})],1)]},proxy:true}])}),_c('div',{staticClass:"vs-mb-8"},[_vm._v(" I contatti inclusi in questa lista saranno esclusi da tutti gli invii ")]),_c('div',{staticClass:"vs-flex md:vs-flex-nowrap vs-my-8 vs-justify-between vs-flex-wrap vs-gap-4"},[_c('div',{staticClass:"vs-flex vs-items-center vs-gap-4"},[_c('VsFilterContainer',{attrs:{"countFilters":_vm.countFilters,"totalFound":_vm.$tc('lists.index.foundLists', _vm.total)},on:{"clear":function($event){_vm.filters = {
                    search: '',
                    source: '',
                    searchFields: 'email_address',
                }}}},[_c('VsSelect',{attrs:{"labelHidden":"","options":[
                        {
                            label: 'Indirizzo Email',
                            value: 'email_address',
                        },
                        {
                            label: 'Telefono cellulare principale',
                            value: 'mobile',
                        } ]},model:{value:(_vm.filters.searchFields),callback:function ($$v) {_vm.$set(_vm.filters, "searchFields", $$v)},expression:"filters.searchFields"}}),_c('VsSelect',{attrs:{"placeholder":"Tutte le sorgenti","labelHidden":"","clearable":"","options":_vm.sourceOptions},model:{value:(_vm.filters.source),callback:function ($$v) {_vm.$set(_vm.filters, "source", $$v)},expression:"filters.source"}}),_c('VsInput',{attrs:{"placeholder":"Cerca...","iconName":"search","labelHidden":"","debounce":500,"clearable":""},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),(_vm.countFilters > 0)?_c('VsButton',{staticClass:"md:vs-hidden",attrs:{"variant":"link"},on:{"click":function($event){_vm.filters = {
                    search: '',
                    source: '',
                    searchFields: 'email_address',
                }}}},[_vm._v(" Svuota filtri ")]):_vm._e(),_c('div',{staticClass:"vs-body-medium vs-flex-none"},[_vm._v(_vm._s(_vm.$tc('lists.index.foundLists', _vm.total)))])],1),_c('div',{staticClass:"vs-flex-initial vs-flex vs-items-center vs-gap-4"},[_c('VsSelect',{staticClass:"vs-hidden md:vs-inline-flex",staticStyle:{"max-width":"134px"},attrs:{"placeholder":_vm.$t('lists.index.orderBy.createdAtDesc'),"labelHidden":true,"clearable":true,"options":[
                    {
                        label: 'Più recenti',
                        value: 'desc',
                    },
                    {
                        label: 'Meno recenti',
                        value: 'asc',
                    } ]},model:{value:(_vm.pagination.orderBy),callback:function ($$v) {_vm.$set(_vm.pagination, "orderBy", $$v)},expression:"pagination.orderBy"}}),_c('VsSelect',{staticClass:"md:vs-hidden",staticStyle:{"max-width":"116px"},attrs:{"placeholder":_vm.$t('lists.index.orderBy.createdAtDesc'),"labelHidden":true,"clearable":true,"size":"small","options":[
                    {
                        label: 'Più recenti',
                        value: 'desc',
                    },
                    {
                        label: 'Meno recenti',
                        value: 'asc',
                    } ]},model:{value:(_vm.pagination.orderBy),callback:function ($$v) {_vm.$set(_vm.pagination, "orderBy", $$v)},expression:"pagination.orderBy"}})],1)]),_c('VsTable',{ref:"suppressionsTable",attrs:{"headers":_vm.tableColumns,"items":_vm.suppressions,"actionColumn":"","fixedAction":"","selectTarget":"id","loading":_vm.loading},scopedSlots:_vm._u([{key:"bodyActions",fn:function(ref){
                var row = ref.row;
return [_c('div',{staticStyle:{"width":"48px"}},[(row.source === 'User')?_c('VsButton',{attrs:{"size":"large","aspect":"ghost","variant":"icon","iconName":"trash"},on:{"click":function($event){return _vm.deleteSuppression(row.id)}}}):_vm._e()],1)]}}])}),_c('VsPagination',{staticClass:"vs-mt-4",attrs:{"totalItems":_vm.total,"filterable":"","expand":"","currentPage":_vm.pagination.page,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}),_c('VsConfirm',{ref:"confirm",attrs:{"closeButton":_vm.$t('general.cancel'),"confirmButton":_vm.$t('general.confirm')}},[_vm._v(" "+_vm._s(_vm.$t('lists.suppression.list.deleteConfirm'))+" ")]),_c('VsAddSuppressionModal',{ref:"addSuppressionModal",on:{"suppression-created":_vm.getSuppressions}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }