var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('lists.suppression.addSuppressionModal.title'))+" ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"suppressionForm"},[_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"label":"Aggiungi per","options":[
                {
                    label: _vm.$t('lists.suppression.list.emailAddress'),
                    value: 'email_address',
                },
                {
                    label: _vm.$t('lists.suppression.list.mobile'),
                    value: 'mobile',
                } ]},model:{value:(_vm.suppressionType),callback:function ($$v) {_vm.suppressionType=$$v},expression:"suppressionType"}}),(_vm.suppressionType === 'email_address')?_c('ValidationProvider',{attrs:{"vid":"email_address","name":_vm.$t('lists.suppression.list.emailAddress'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInput',{attrs:{"errorMessage":errors[0],"error":errors.length > 0,"label":_vm.$t('lists.suppression.list.emailAddress')},model:{value:(_vm.emailAddress),callback:function ($$v) {_vm.emailAddress=$$v},expression:"emailAddress"}})]}}],null,false,2010409123)}):_vm._e(),(_vm.suppressionType === 'mobile')?_c('ValidationProvider',{attrs:{"vid":"mobile","name":_vm.$t('lists.suppression.list.mobile'),"rules":"required|libphoneNumberMobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsPhoneInput',{attrs:{"errorMessage":errors[0],"error":errors.length > 0,"label":_vm.$t('lists.suppression.list.mobile')},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}})]}}],null,false,1765740159)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }