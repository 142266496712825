














































































import { Component, Vue } from 'vue-property-decorator'
import { createSuppression } from '@/api/consoleApi/suppressions'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import axios from 'axios'

@Component({
    name: 'VsAddSuppressionModal',
})
export default class extends Vue {
    private show = false
    private loading = false
    private mobile = ''
    private emailAddress = ''
    private suppressionType = 'email_address'
    $refs: any

    public async openModal () {
        this.loading = false
        this.mobile = ''
        this.emailAddress = ''
        this.suppressionType = 'email_address'
        this.show = true
    }

    public closeModal () {
        this.show = false
    }

    private async handleSubmit () {
        const success = await this.$refs.suppressionForm.validate()
        if (!success) return
        this.loading = true
        try {
            await createSuppression({ [this.suppressionType]: this.suppressionType === 'email_address' ? this.emailAddress : this.mobile })
            this.$emit('suppression-created')
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.addSuppressionModal.success'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: this.$t('lists.suppression.addSuppressionModal.error'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    if (e.response?.data?.message?.mobile && e.response.data.message.mobile[0].includes('already suppressed')) {
                        this.$refs.suppressionForm.setErrors({
                            mobile: this.$t('lists.suppression.addSuppressionModal.errorMobile'),
                        })
                    }
                    if (e.response?.data?.message?.email_address && e.response.data.message.email_address[0].includes('already suppressed')) {
                        this.$refs.suppressionForm.setErrors({
                            email_address: this.$t('lists.suppression.addSuppressionModal.errorEmail'),
                        })
                    }
                }
            }
        }
        this.loading = false
    }
}
